import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import HeroV2 from '../components/HeroV2';
import Image from '../components/Image';
import CareerCallout from './partials/_CareerCallout';
// import HeroImage from '../images/hero/hero-cybersecurity.png';

const textOverlay = (
  <div className="home__text_overlay_container">
    <Container fullWidth={true}>
      <div className="home__text_overlay_inner_container">
        <span className="home__text_overlay_title">Cybersecurity & STEM</span>
      </div>
    </Container>
  </div>
);

const CyberSecurityPage = ({ data }) => (
  <Layout title="Cyber Security">
    {/* <Hero title="Cybersecurity &amp; STEM" image={HeroImage} /> */}
    <HeroV2
      mediaSource="cyber-hero-img-1400x366-2x.jpg"
      textOverlay={textOverlay}
    />
    <Section>
      <Container>
        <h2>
          Activate every student’s superpowers with essential cyber smarts
        </h2>
        <Row>
          <Column size={8}>
            <p>
              Our ever-evolving digital world offers young people so much room
              to soar! Inspire middle school students to decode their everyday
              lives and hack into a better future for our planet using the
              basics of Cybersecurity and Artificial Intelligence (A.I.).
              Empower the next generation of STEM stars with the help of
              interactive{' '}
              <Link to="/career-profiles" className="text-bold">
                career profiles
              </Link>
              .
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    <div className="cybersecurity__disciplines">
      <Section>
        <Container>
          <Row verticalAlignCenter>
            <Column xsmall={6} xsmallOffset={3} medium={2} mediumOffset={0}>
              <Image filename="icon-all-things-digital.svg" />
            </Column>
            <Column medium={8} mediumOffset={1}>
              <h2>All Things Digital</h2>
              <p>
                <strong>
                  Do you realize that everyone today is leading a “double life”?
                </strong>{' '}
                As technology continues to impact every aspect of our society,
                people now have citizenship in both the physical and the digital
                world! Empower students to lean into digital innovation and
                online safety today to find balance and success tomorrow.
              </p>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row verticalAlignCenter>
            <Column xsmall={6} xsmallOffset={3} medium={2} mediumOffset={1}>
              <Image filename="icon-cyrptology.svg" />
            </Column>
            <Column medium={8}>
              <h2>Cryptology</h2>
              <p>
                <strong>
                  Uncover secrets and protect your information using super cyber
                  stealth skills!
                </strong>{' '}
                Unlock the mysteries of cybersecurity with the keys of
                encryption, a crucial component of all security technologies.
                Cultivate a new generation of codebreakers to keep our online
                communities safe, using the magic of mathematics.
              </p>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row verticalAlignCenter>
            {' '}
            <Column xsmall={6} xsmallOffset={3} medium={2} mediumOffset={0}>
              <Image filename="icon-fraud-detection.svg" />
            </Column>
            <Column medium={8} mediumOffset={1}>
              <h2>Fraud Detection</h2>
              <p>
                <strong>
                  Seeing through cyber camouflage takes equal parts intuition
                  and innovation.
                </strong>{' '}
                To protect against hackers, it’s crucial to actively investigate
                and analyze digital clues. Show students that the simple power
                of pattern recognition is all it takes to solve cybercrimes and
                become a formidable Fraud Detective.
              </p>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row verticalAlignCenter>
            <Column xsmall={6} xsmallOffset={3} medium={2} mediumOffset={1}>
              <Image filename="icon-cybersecurity.svg" />
            </Column>
            <Column medium={8}>
              <h2>Cybersecurity</h2>
              <p>
                <strong>
                  Hacker attacks and cyber crooks require a new kind of
                  crimefighter.
                </strong>{' '}
                The virtual goldmine known as “Data” is attracting all kinds of
                cybercriminals looking to profit illegally from online
                information. Equip students with the tools they need to avoid
                data breaches and become the future’s digital defenders.
              </p>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row verticalAlignCenter>
            {' '}
            <Column xsmall={6} xsmallOffset={3} medium={2} mediumOffset={0}>
              <Image filename="icon-internet-of-things.svg" />
            </Column>
            <Column medium={8} mediumOffset={1}>
              <h2>Internet of Things</h2>
              <p>
                <strong>
                  When everything is connected, nothing is impossible!
                </strong>{' '}
                As the 4th Industrial Revolution redefines the connection
                between students’ physical and digital lives via the Internet of
                Things (IoT), the possibilities of harnessing data are out of
                this world. Discover how Artificial Intelligence (A.I.) is
                driving a data explosion.
              </p>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row verticalAlignCenter>
            <Column xsmall={6} xsmallOffset={3} medium={2} mediumOffset={1}>
              <Image filename="icon-ai-social-good.svg" />
            </Column>
            <Column medium={8}>
              <h2>A.I for Social Good</h2>
              <p>
                <strong>
                  Can you help redefine “the greater good” for our digitally
                  diverse world?
                </strong>{' '}
                Cyber smarts can be used for so much more than just personal
                success! Empower tomorrow’s tech heroes to tackle our toughest
                and most complex social problems using game-changing advances in
                A.I. and data science.  
              </p>
            </Column>
          </Row>
        </Container>
      </Section>
    </div>
    <CareerCallout
      title="Security Engineer"
      description={
        <>
          Find out how computer systems can be carefully designed with protocols
          for withstanding disruptions caused by <strong>cyberattacks</strong>
        </>
      }
      path="/career-profiles/security-engineer"
      img={'career-profiles/career-profiles-annmarie-headshot.jpg'}
      name="Anne Marie Zettlemoyer"
      job="Vice President, Security Engineering, Mastercard"
    />
  </Layout>
);

export default CyberSecurityPage;
